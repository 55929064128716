import styles from './SquareAnimation.module.scss'
export default function SquareAnimation() {
  const items = new Array(36).fill(null)

  return (
    <div className={styles.animation}>
      <div className={styles.gridWrapper}>
        {items.map((_, index) => (
          <div key={index} className={styles.grid}></div>
        ))}
      </div>
    </div>
  )
}
