import styles from './EllipseAnimation.module.scss'
export default function EllipseAnimation() {
  return (
    <div className={styles.circle}>
      <div className={styles.wrapper}>
        <div className={styles.visual}>
          <div className={styles.round}></div>
        </div>
      </div>
    </div>
  )
}
