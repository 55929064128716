import useMediaQuery from '@/functions/useMediaQuery'
import {lg} from 'config'
import CircleAnimation from '../CircleAnimation'
import EllipseAnimation from '../EllipseAnimation'
import SquareAnimation from '../SquareAnimation'

type Animationprops = {
  animation: string
}

export default function Animation({animation}: Animationprops) {
  const isLg = useMediaQuery(lg)
  if (animation === 'circle' && isLg) return <CircleAnimation />
  if (animation === 'inner-circle' && isLg) return <EllipseAnimation />
  if (animation === 'square' && isLg) return <SquareAnimation />
  return <></>
}
