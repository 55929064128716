export default function CircleAnimationSVG() {
  // prettier-ignore
  /* eslint-disable */
  return (
<svg width="106" height="106" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
<path d="M97.5959 26.0969C102.365 33.9588 105.111 43.1861 105.111 53.0551C105.111 80.6562 83.6283 103.241 56.4715 105H56.4688" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M8.67454 25.836C8.67454 25.8388 8.67065 25.8427 8.66953 25.8455C3.80454 33.7636 1 43.0815 1 53.0567C1 80.4148 22.1039 102.843 48.9214 104.951H48.9281" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M13.0776 19.7142L13.0843 19.7064C21.806 9.26403 34.5424 2.29188 48.9216 1.16239C50.2852 1.05413 51.6626 1 53.0536 1C54.203 1 55.3423 1.03708 56.4716 1.11123C71.2529 2.06887 84.3535 9.19674 93.2232 19.9439L93.2271 19.9478" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
</g>
<g opacity="0.5">
<path d="M95.8339 44.8511C96.348 47.556 96.6065 50.3034 96.6058 53.0567C96.6058 77.1081 77.108 96.6059 53.0555 96.6059C49.2247 96.6089 45.4102 96.107 41.7106 95.1132C41.3969 95.0291 41.0844 94.9412 40.7729 94.8496" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M23.3436 21.2163C14.8282 29.1639 9.50439 40.4894 9.50439 53.0567C9.50439 70.1732 19.3801 84.985 33.7459 92.1035" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M70.2185 13.0201C81.0307 17.6599 89.5873 26.5518 93.7816 37.5942" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M29.2817 16.5637C29.4197 16.472 29.5598 16.3824 29.6994 16.2934C34.6052 13.1833 40.0822 11.0832 45.8098 10.1161C51.5373 9.14891 57.4002 9.33417 63.0553 10.661" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
</g>
  <g>
<path d="M51.3799 18.0775C51.936 18.0508 52.4945 18.0377 53.0555 18.038C61.4173 18.0259 69.505 21.0185 75.845 26.4706" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M30.7024 80.0127C36.9797 85.2347 44.8905 88.0884 53.0559 88.0765C68.5162 88.0765 81.6357 78.0574 86.2783 64.1587" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M25.448 74.5999C20.635 68.4514 18.0256 60.865 18.0381 53.0567C18.0381 36.8734 29.0138 23.255 43.9291 19.2404" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M81.0115 31.9645C85.606 38.0353 88.0866 45.4434 88.0743 53.0567C88.0745 54.3025 88.0088 55.5473 87.8774 56.7861" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
</g>
<g opacity="0.5">
<path d="M72.8562 70.6865C76.8829 66.1786 79.2404 60.4253 79.5347 54.3881" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M26.7314 49.8835C28.3003 36.7361 39.4879 26.544 53.0534 26.544C55.6188 26.5418 58.1708 26.9125 60.6295 27.6446" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M26.9023 57.4223C28.9845 69.9869 39.9018 79.5678 53.0536 79.5678C58.0175 79.5766 62.883 78.1843 67.0908 75.5509" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M67.4786 30.8083C67.6889 30.944 67.8963 31.0829 68.1009 31.2249C73.4008 34.8836 77.3032 40.4227 78.8431 46.8776" stroke="#0567EC" strokeMiterlimit="10" strokeLinecap="round"/>
</g>
</svg>


  )
}
