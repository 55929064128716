import styles from './CircleAnimation.module.scss'
import CircleAnimationSVG from './CircleAnimationSVG'
export default function CircleAnimation() {
  return (
    <div className={styles.circle}>
      <div className={styles.circleContainer}>
        <CircleAnimationSVG />
      </div>
    </div>
  )
}
